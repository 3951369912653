
				@use "sass:map";
				@import "./www/assets/styles/_variables.scss";
		
@keyframes turn {
  to {
    transform: rotate(360deg);
  }
}

.animation-turn {
  animation: turn 300ms;
}

.UserDevices {

}

.Filling {
  .step {
    > span {
      font-size: 0.875rem;
    }
    > select {
      font-size: 0.875rem;
      border: none;
      background-position-x: calc(100% + 8px);
      border-bottom: 3px solid $blue;
      padding: 0 2px;
      height: auto;
    }
  }
}

.Questions {
  .QuestionChart {
    min-height: 200px;
    flex-basis: 100%;
  }
  .yMax {
    position: absolute;
    right: 1rem;
    top: 1rem;

    label {
      font-size: 80%;
      display: inline-block;
      padding-right: 5px;
    }
    select {
      width: fit-content;
      height: 24px;
      font-size: 80%;
    }
  }
}

.FeedbackOverview {
  .parts-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
  }

  .rating-stars {
    width: 40%;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 4px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background 0.2s, transform 0.2s;

    &:hover {
      transform: translateY(-3px);
    }


    .stars {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      .star:before {
        font-size: 4.5rem;
      }

      .icon {
        font-size: 1.5rem;
        color: gold;
      }
    }

    .avg-rating {
      text-align: center;
      margin-top: 0.5rem;
      font-size: 2rem;
      font-weight: bold;
    }

    .count,
    .label {
      color: $c-text-gray;;
      text-align: center;
    }
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 55%;

    .checkbox {
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-template-rows: auto auto;
      gap: 0 20px;
      align-items: center;
      padding: 8px 12px;
      border-radius: 6px;
      background: #F3F3F3;
      border: 1px solid #ddd;
      transition: background 0.2s, transform 0.2s;

      &:hover {
        transform: translateY(-3px);
      }

      .label {
        grid-column: 1 / 2;
        grid-row: 1 / span 2;
      }

      .ratio {
        grid-column: 2 / 3;
        grid-row: 1 / span 2;
        font-weight: 600;
        text-align: center;
      }

      .values {
        grid-column: 3 / 4;
        grid-row: 1 / span 2;
        display: grid;
        grid-template-rows: repeat(2, auto);
        gap: 4px;

        .value {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 0.85em;
          background: #fff;
          border: 1px solid #ccc;

          .count {
            font-weight: bold;
            min-width: 40px;
            text-align: right;
          }

          .label {
            font-weight: normal;
          }
        }
      }
    }
  }

}

